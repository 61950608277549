<template>
<!-- 设备配置 -->
  <div class="box100">
    <el-dialog title="配置" :visible.sync="deployDialog" width="900px" center class="gyDialog fromDialog" :close-on-click-modal="false" :before-close="deployCancel" top="8vh">
      <div class="deploy_content" :class="detailInfo.equtype === '21' && ctorShow === false ? 'contentScroll' : ''">
        <!-- 视频联动1 - 可视对讲2 - AI智能摄像20 -->
        <div class="play" v-if="detailInfo.equtype === '1' || detailInfo.equtype === '2' || detailInfo.equtype === '20'" style="margin-bottom: 12px;">
          <div class="flexAC" style="margin-bottom: 12px;">
            <div class="udi_k"></div><p class="udi_t">固定配置参数</p>
          </div>
          <div class="playBox">
            <div class="box100" v-if="detailInfo.equtype === '2' || detailInfo.equtype === '20'">
              <div id="video-container"></div>
            </div>
            <div class="box100" v-if="detailInfo.equtype === '1'">
              <video ref="videoElement" id="videoElement" controls autoplay muted style="width: 100%; height:390px"></video>
            </div>
          </div>
        </div>

        <!-- 设备基本信息 -->
        <div class="info" v-if="!ctorShow">
          <div class="flexAC" style="margin-bottom: 12px;">
            <div class="udi_k"></div><p class="udi_t">设备基本信息</p>
          </div>
          <div class="flexSC">
            <div class="ud_left">
              <p class="udi_p">单位名称：{{ detailInfo.companyname }}</p>
              <div class="flexCB">
                <p class="udi_s">设备类型：{{ detailInfo.equtypename }}</p>
                <p class="udi_s" v-if="detailInfo.equtype != 2 && detailInfo.equtype != 1 && detailInfo.equtype != 6" >IMEI码：{{ detailInfo.equimei }}</p>
                <p class="udi_s" v-if="detailInfo.equtype == 1">账号：{{ detailInfo.equimei }}</p>
                <p class="udi_s" v-if="detailInfo.equtype == 2">设备序列号：{{ detailInfo.equimei }}</p>
                <p class="udi_s" v-if="detailInfo.equtype == 6">鉴权信息：{{ detailInfo.equimei }}</p>
              </div>
              <div class="flexCB">
                <p class="udi_s" v-if="detailInfo.equtype != 1">设备名称：{{ detailInfo.equname }}</p>
                <p class="udi_s" v-if="detailInfo.equtype == 1">密码：{{ detailInfo.equname }}</p>
                <p class="udi_s" v-if="detailInfo.equtype != 1 && detailInfo.equtype != 2 && detailInfo.equtype != 3">设备型号：{{ detailInfo.equcode }}</p>
                <p class="udi_s" v-if="detailInfo.equtype == 1 ||detailInfo.equtype == 3">虚拟IP：{{ detailInfo.equcode }}</p>
                <p class="udi_s" v-if="detailInfo.equtype == 2">设备验证码：{{ detailInfo.equcode }}</p>
              </div>
              <div class="flexCB">
                <p class="udi_s">传输方式：{{ detailInfo.transmission_name }}</p>
                <p class="udi_s">设备位置：{{ detailInfo.adderss }}</p>
              </div>
              <div class="flexCB">
                <p class="udi_s" v-if="detailInfo.equtype == 6">设备ID：{{ detailInfo.equdtuid }}</p>
                <p class="udi_s" v-if="detailInfo.equtype == 6">产品ID：{{ detailInfo.productid }}</p>
              </div>
              <div class="flexCB">
                <p class="udi_s">入网时间：{{ detailInfo.creatime }}</p>
                <p class="udi_s" v-if="detailInfo.equtype == 9">消防水箱尺寸(单位： 米)：长：{{ detailInfo.equlong }}， 宽：{{ detailInfo.equwide }}， 高：{{ detailInfo.equhigh }}</p>
              </div>
            </div>
            <div class="ud_right">
              <img :src="detailInfo.equ_image" :onerror="errorImg" class="uni_img"/>
            </div>
          </div>
        </div>

        <!-- DTU导入 消防主机联网6 -->
        <div class="dtu" v-if="detailInfo.equtype === '6'">
          <div class="flexAC" style="margin: 12px 0px;">
            <div class="udi_k"></div><p class="udi_t">关联设备导入</p>
          </div>
          <div class="dtu_up">
            <p>上传相关附件</p>
            <div class="upload_box">
              <el-upload
                class="upload-demo"
                :action="action"
                :headers="headers"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess">
                <div style="display: flex;justify-content: flex-start;">
                  <div class="upload_btn"><i class="el-icon-upload2"></i>上传文件</div>
                  <div class="upload_t">支持扩展名：.xlsx .xls</div>
                </div>
              </el-upload>
            </div>
            <div class="fileBox " style="height: 20px;margin-top:20px;" >
              <div class="flexCB" v-if="upSuccess">
                <p><span class="iconfont iconfujian"></span><span class="upFileName">{{ fileName }}</span></p>
                <span class="el-icon-circle-close upIco" @click="upDelete"></span>
              </div>
            </div>
          </div>
        </div>

        <!-- 数据传输终端 22 -->
        <div class="dataTerminal" v-if="detailInfo.equtype === '22'">
          <EQ :detailInfo="detailInfo"></EQ>
        </div>

        <!-- 配置参数 - [大多数] - 除智能断路器21 -->
        <div class="parameter" v-if="detailInfo.equtype !== '21'">
          <!-- 固定配置参数 -->
          <div class="parBox" v-if="basisShow">
            <div class="flexAC" style="margin: 12px 0;">
              <div class="udi_k"></div><p class="udi_t">固定配置参数</p>
            </div>
            <div style="display: flex;flex-wrap: wrap;">
              <p v-for="(item, index) in detailInfo.equcf_basis" :key="index" class="udi_c">{{item.name}}：{{ item.value }}{{ item.unit }}</p>
            </div>
          </div>
          <!-- 动态配置参数 -->
          <div class="parBox" v-if="equcfShow">
            <div class="flexAC" style="margin: 12px 0px;">
              <div class="udi_k"></div><p class="udi_t">动态配置参数</p>
            </div>
            <div class="eqconfig" v-for="item in detailInfo.equcf_dynamic" :key="item.index">
              <div style="margin-bottom: 5px;margin-right: 10px;">{{item.name}}
                <span class="csFw">范围区间{{ item.scope }}/{{ item.unit }}</span>
              </div>
              <el-input v-model="item.value"  style="width: 257px;margin-right: 10px;"></el-input>
              <span class="csUnit">{{ item.unit }}</span>
            </div>
          </div>
        </div>

        <!-- 智能断路器 21 -->
        <div class="Intelligent" v-if="detailInfo.equtype === '21'">
          <!-- 断路器参数配置 -->
          <div class="intel_info" v-if="!ctorShow">
            <div class="intelBox">
              <div class="flexAC" style="margin: 12px 0;">
                <div class="udi_k"></div><p class="udi_t">基本配置参数</p>
              </div>
              <div style="display: flex;flex-wrap: wrap;">
                <p class="udi_c" v-for="(item, index) in bassList" :key="index">
                  <span style="font-size: 14px;font-weight: bold;color: #333333;">{{item.name}}：</span>
                  <span style="font-size: 14px;color: #8291A1;">{{item.value}}</span>
                </p>
              </div>
              <div class="flexCB">
                <div class="flexAC" style="margin: 12px 0;">
                  <div class="udi_k"></div><p class="udi_t">其他配置参数</p>
                </div>
                <div class="settingConfirm" @click="handleCtor" v-if="!configLength">断路器新增</div>
                <div class="settingConfirm" @click="editCtor" v-if="configLength">断路器编辑</div>
              </div>
              <!-- 断路器信息 -->
              <div style="padding: 10px;" v-if="configLength">
                <el-tabs v-model="activeName">
                  <el-tab-pane v-for="(item, index) in configList" :key="index"  :label="'断路器'+ item.ctuname" :name="item.ctuid">
                    <div style="display: flex;flex-wrap: wrap;">
                      <p class="ud_one">断路器{{item.ctuname}}</p>
                      <p class="ud_two"><span style="color:#333333;font-weight: bold;">{{item.ctuvery}}</span>({{item.ctumark}})</p>
                      <div class="ud_three" :class="{ ud_three1:item.ctuswit == true, ud_three2:item.ctuswit == false }">
                        <div v-if="item.ctuswit === true ">开关
                          <el-switch @change="handleSwitch($event,item)" v-model="item.ctuswit"> </el-switch>
                        </div>
                        <div v-if="item.ctuswit === false">开关
                          <el-switch @change="handleSwitch($event,item)" v-model="item.ctuswit"> </el-switch>
                        </div>
                      </div>
                    </div>
                    <!-- 关联设备 -->
                    <div class="flexSC">
                      <div style="width: 100%;">
                        <div class="flexAC udiTitle"><div class="udi_y"></div><p class="udi_t">关联设备</p></div>
                        <div style="display: flex;flex-wrap: wrap;">
                          <p class="udi_r" v-for="(bdEqu,index) in item.ctuasso" :key="index">{{bdEqu.name}}</p>
                        </div>
                      </div>
                    </div>
                    <!-- 固定配置参数 -->
                    <div style="width:100%;" v-if="judge(item.ctubass)">
                      <div class="flexAC udiTitle"> <div class="udi_y"></div><p class="udi_t">固定配置参数</p></div>
                      <div style="display: flex;flex-wrap: wrap;">
                        <p v-for="(gdcs, index) in item.ctubass" :key="index" class="udi_c"><span style="color:#333333;font-weight: bold;">{{gdcs.name}}</span>：{{ gdcs.value }}{{ gdcs.unit }}</p>
                      </div>
                    </div>
                    <!-- 动态配置参数 -->
                    <div style="width:100%;" v-if="judge(item.ctudyna)">
                      <div class="flexAC udiTitle"><div class="udi_y"></div><p class="udi_t">动态配置参数</p></div>
                      <div class="eqconfig" v-for="(dtcs, index) in item.ctudyna" :key="index">
                        <div style="margin-bottom: 5px;margin-right: 10px;">{{dtcs.name}}<span class="csFw">范围区间{{ dtcs.scope }}/{{ dtcs.unit }}</span></div>
                        <el-input v-model="dtcs.value"  style="width: 257px;margin-right: 10px;"></el-input>
                        <span class="csUnit">{{ dtcs.unit }}</span>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>

          <!-- 断路器表单提交 -->
          <div class="intel_from" v-if="ctorShow">
            <div class="ctoradd"  @click="addConfig">添加</div>
            <div class="contentScroll">
              <div v-for="(item,index) in arrayData" :key="item.id">
                <div class="flexCB">
                  <div class="flexAC" style="margin-bottom: 12px;">
                    <div class="udi_k"></div><p class="udi_t">断路器{{index+1}}</p>
                  </div>
                  <p style="color: #FF4D4F;padding-right: 30px;cursor: pointer;" @click="deleteConfig(item,index)">删除</p>
                </div>
                <div style="margin: 10px 0;">
                  <p class="ud_tit">断路器基本信息</p>
                  <div class="flexF">
                    <el-select v-model="item.ctuvery" placeholder="下拉选择极数" style="width: 188px;">
                      <el-option
                        v-for="(item,index) in numpOption"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <el-input v-model="item.ctucode"  placeholder="请输入断路器型号" style="width: 188px;margin: 0 20px;" ></el-input>
                    <el-input v-model="item.ctumark"  placeholder="请输入断路器线路说明"  style="width: 400px;" :maxlength="15"></el-input>
                  </div>
                  <p class="ud_tit"  style="margin-top: 10px;">关联设备</p>
                  <div class="flexF">
                    <div v-for="(glequ,eq) in item.ctuasso" :key="eq">
                      <el-input placeholder="请输入关联设备" v-model="glequ.name" style="width: 240px;margin:3px 0;"></el-input>
                      <i class="el-icon-circle-close udi_i" @click="deleteCtor(item,eq)" ></i>
                    </div>
                    <div class="iconfont iconjia udi_a" style="width: 240px;" @click="addCtor(item)" >添加</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flexCE" style="margin-top:20px;">
              <div class="settingCancel eqbut" @click="returnCtor">返回</div>
              <div class="settingConfirm eqbut" @click="confirmCtor">保存</div>
            </div>
          </div>
        </div>

        <!-- 重置保存按钮  -->
        <div class="flexCE" style="margin-top:20px;">
          <!-- dtu-消防主机联网 -->
          <div class="settingCancel eqbut" v-if="detailInfo.equtype === '6'" @click="cancelDTU">重置</div>
          <div class="settingConfirm eqbut" v-if="detailInfo.equtype === '6'" @click="confirmDTU">保存</div>

          <!--  -->
          <div class="settingCancel eqbut" v-if="!ctorShow && basisShow && equcfShow" @click="cancelChange">重置</div>
          <div class="settingConfirm eqbut" v-if="!ctorShow && basisShow && equcfShow" @click="confirmChange">保存</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { equCurrent, getCircuitEqu, openCircuitEqu, addCircuitEqu, importDTUinfo, updateConfig } from '@/http/api/equip'
import EQ from '@/components/setting/eq.vue'
import EZUIKit from 'ezuikit-js'
import Hls from 'hls.js'
import flvjs from 'flv.js'
import $ from 'jquery'
import Recorderx from 'recorderx'
const rc = new Recorderx()
export default {
  name: 'equipDeploy',
  props: ['deployRow'],
  components: {
    EQ
  },
  data () {
    return {
      deployDialog: true,
      detailInfo: '',
      errorImg: 'this.src="' + require('@/assets/img/unit_default.png') + '"',
      spurl: '',
      flvPlayer: null,
      hlsPlayer: null,
      basisShow: false,
      equcfShow: false,
      action: this.BASE_URL + this.$url.PATH.annex,
      fileName: '',
      upSuccess: false,
      ctorShow: false,
      bassList: [],
      configList: [],
      configLength: false,
      arrayData:[{
        ctuid: '',
        name: '',
        ctuvery: '',
        ctucode: '',
        ctumark: '',
        ctuasso: []
      }],
      activeName: '',
      numpOption: [
        { label: '1P', value: '1P' },
        { label: '2P', value: '2P' },
        { label: '3P', value: '3P' },
        { label: '4P', value: '4P' }
      ],
      parBtnShow: false
    }
  },
  computed: {
    headers () {
      return {
        Authorization: window.localStorage.getItem('token'),
        Loginsource: '1',
        Anotherplace: this.$url.PATH.Anotherplace
      }
    }
  },
  mounted () {
    window.URL = window.URL || window.webkitURL
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia

    this.detailInfo = this.deployRow
    // console.log(this.detailInfo)
    if (this.detailInfo.equtype === '1') { // 视频联动1
      this.getFlvVideo()
    } else if (this.detailInfo.equtype === '2' || this.detailInfo.equtype === '20') {
      this.getMicrophone() // 获取麦克风权限
      this.$nextTick(() => {
        this.getYsyVideo() // 可视对讲2 - Ai智能摄像20
      })
    } else if (this.detailInfo.equtype === '21') {
      this.ctorShow = false
      this.getCtor() // 智能断路器21
    }
    /* 动态参数 */
    if (this.detailInfo.equcf_dynamic.length === 0) { this.equcfShow = false }
    else { this.equcfShow = true }
    if (this.detailInfo.equcf_basis.length === 0) { this.basisShow = false}
    else { this.basisShow = true }
  },
  methods: {
    /* 视频联动 1  */
    getFlvVideo () {
      this.spurl = ''
      equCurrent({ equid: this.detailInfo.equid }).then(res => {
        if (res.status === '1') {
          this.spurl = res.data.video_token
          console.log(this.spurl)
          if (this.spurl.indexOf('hls') > 0) { /* Hls */
            if (Hls.isSupported()) {
              const _videoBox = this.$refs.videoElement
              this.hlsPlayer = new Hls()
              this.hlsPlayer.loadSource(this.spurl)
              this.hlsPlayer.attachMedia(_videoBox)
              this.hlsPlayer.on(Hls.Events.MANIFEST_PARSED, () => {
                this.$refs.videoElement.play()
              })
            } else {
              console.log('不支持的hls格式')
            }
          } else if (this.spurl.indexOf('flv') > 0) { /* Flv */
            if (flvjs.isSupported()) {
              const videoBox = this.$refs.videoElement
              console.log(videoBox)
              this.flvPlayer = flvjs.createPlayer({
                type: 'flv',
                isLive: true,
                hasAudio: false,
                hasVideo: true,
                url: this.spurl
              })
              this.flvPlayer.attachMediaElement(videoBox)
              this.flvPlayer.load()
              this.flvPlayer.play()
            } else {
              console.log('不支持flv格式')
            }
          }
        }
      })
    },
    /* 获取麦克风权限 */
    getMicrophone () {
      rc.start().then(() => {
        console.log('获取麦克风成功')
      }).catch(error => {
        console.log('失败原因' + error)
      })
    },
    /* 萤石云 - 可视对讲2 - Ai智能摄像20 */
    getYsyVideo () {
      equCurrent({ equid: this.detailInfo.equid }).then(res => {
        if (res.status === '1') {
          this.player = new EZUIKit.EZUIKitPlayer({
            autoplay: true,
            id: 'video-container',
            accessToken: res.data.video_token,
            url: res.data.equip_port,
            template: 'voice', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
            header: ['capturePicture', 'save', 'zoom'], // 如果templete参数不为simple,该字段将被覆盖
            footer: ['talk', 'hd', 'fullScreen'], // 如果template参数不为simple,该字段将被覆盖
            // plugin: ['talk'],
            audio: 1,
            // width: 840,
            height: 290
          })
        }
      })
    },
    /* DTU配置 上传文件 6 */
    beforeAvatarUpload (file) {
      const type = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isJPG = 'xlsx/xls'.includes(type)
      const isLt50M = file.size / 1024 / 1024 < 50
      if (!isJPG) {
        this.$message.error('请上传支持的类型文件')
        return false
      } else if (!isLt50M) {
        this.$message.error('上传文件大小不能超过 50MB!')
        return false
      } else {
        this.fileName = file.name
        return isJPG && isLt50M
      }
    },
    /* DTU配置 上传成功 */
    handleAvatarSuccess (res) {
      if (res.status === '1') {
        this.upSuccess = true
        this.filepath = res.data.filepath
      } else {
        this.upSuccess = false
        this.$message.error(res.message)
      }
    },
    upDelete () {
      this.filepath = ''
      this.fileName = ''
      this.upSuccess = false
    },
    /* DTU配置 - 重置 */
    cancelDTU () {
      this.upDelete()
    },
    /* DTU配置 - 保存 */
    confirmDTU () {
      const params = {
        equid: this.detailInfo.equid,
        filepath: this.filepath,
        companyid: this.detailInfo.companyid
      }
      importDTUinfo(params).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          this.$emit('closeDeploy')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /* 断路器配置参数 */
    getCtor () {
      getCircuitEqu({ equid: this.detailInfo.equid }).then(res => {
        if (res.status === '1') {
          this.bassList = res.data.bass
          this.configList = res.data.config
          if (this.configList.length === 0 || this.configList.length === null || this.configList.length === undefined) {
            this.configLength = false
          } else {
            this.configLength = true
            this.activeName = this.configList[0].ctuid
          }
        }
      })
    },
    /* 断路器-新增-展示 */
    handleCtor () {
      this.ctorShow = !this.ctorShow
      this.arrayData = [{
        ctuid: '',
        name: '',
        ctuvery: '',
        ctucode: '',
        ctumark: '',
        ctuasso: []
      }]
    },
    /* 断路器-新增-操作 */
    addConfig () {
      this.arrayData.push({
        ctuid: '',
        name: '',
        ctuvery: '',
        ctucode: '',
        ctumark: '',
        ctuasso: []
      })
    },
    /* 断路器-编辑-删除操作 */
    deleteConfig (item,index) {
      if(this.arrayData.length <= 1){
        return false
      }
      this.arrayData.splice(index,1)
    },
    /* 断路器-编辑-关联设备删除 */
    deleteCtor (item,index) {
      if(item.ctuasso.length <=1 ){
        return false
      }
      item.ctuasso.splice(index,1)
    },
    /* 断路器-编辑-关联设备添加 */
    addCtor (item) {
      item.ctuasso.push({ name: '' })
    },
    /* 断路器-编辑-展示 */
    editCtor () {
      this.ctorShow = !this.ctorShow
      this.arrayData = this.configList
    },
    /* 断路器-编辑-返回 */
    returnCtor () {
      this.ctorShow = !this.ctorShow
      this.getCtor()
    },
    /* 断路器-编辑-保存 */
    confirmCtor () {
      let arr = []
      this.arrayData.forEach((item, index) => {
        let obj = {
          name: index + 1,
          nump: item.ctuvery,
          code: item.ctucode,
          mark: item.ctumark,
          asso: item.ctuasso,
          id: item.ctuid
        }
        arr.push(obj)
      })
      const params = { equid: this.detailInfo.equid, config: arr }
      addCircuitEqu(params).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          this.ctorShow = !this.ctorShow
          this.getCtor()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /* 断路器 - 合闸分闸 */
    handleSwitch (value,item) {
      let tit = ''
      if (item.ctuswit === true) { tit = '通电' }
      else { tit = '断电' }
      item.ctuswit = !value
      this.$confirm('确定是否对“断路器'+item.ctuname+'”进行'+tit+'操作?', '断路器操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        const params = {
          equid: item.equid,
          equimei: item.equimei,
          ctuname: item.ctuname,
          ctuswit: item.ctuswit,
          ctuid: item.ctuid
        }
        openCircuitEqu(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.getCtor()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    /* 重置 */
    cancelChange () {
      this.$emit('closeDeploy')
    },
    /* 保存 */
    confirmChange () {
      // console.log(this.detailInfo.equcf_dynamic)
      if (this.screenArray(this.detailInfo.equcf_dynamic)) {
        const params = {
          equid: this.detailInfo.equid,
          equcf_dynamic: this.detailInfo.equcf_dynamic
        }
        updateConfig(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.cancelChange()
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.error('请输入区间范围内的值')
      }
    },
    screenArray (arr) {
      let af = true
      arr.forEach(item => {
        if (!this.screenItem(item)) {
          af = false
        }
      })
      return af
    },
    screenItem (item) {
      let flag = false
      if (item.value < item.minvle) {
        flag = false
      } else if (item.value > item.maxvle) {
        flag = false
      } else {
        flag = true
      }
      return flag
    },
    /* 关闭 - 配置弹框 */
    deployCancel () {
      this.$emit('closeDeploy')

      if (this.detailInfo.equtype === '1') {
        $('#videoElement').empty()
        if(this.spurl.indexOf('hls') > 0 ){
          this.hlsPlayer.destroy()
          this.hlsPlayer = null
        } else if (this.spurl.indexOf('flv') > 0 ) {
          this.flvPlayer.pause()
          this.flvPlayer.unload()
          this.flvPlayer.detachMediaElement()
          this.flvPlayer.destroy()
          this.flvPlayer = null
        }
      } else if (this.detailInfo.equtype === '2' || this.detailInfo.equtype === '20') {
        $('#video-container').empty()
      }
    },
    judge (item) {
      if (item === null || item === undefined || item.length === 0) {
        return false
      } else {
        return  true
      }
    }
  }
}
</script>

<style scoped>
.contentScroll {
  height: 600px;
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.contentScroll::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.el-dialog__wrapper {
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.el-dialog__wrapper::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.ud_left{
  width: 650px;
}
.ud_right{
  width: 160px;
}
.uni_img{
  width: 160px;
  height: 160px;
  border-radius: 10px;
}
.playBox {
  width: 100%;
  height: 390px;
}
.udi_c {
  width: 257px;
  height: 35px;
  line-height: 20px;
  background: #F6F9FF;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
  margin-right: 10px;
}
.parBox{
  width:100%;
  overflow:hidden;
}
.eqconfig {
  display: flex;
  flex-direction: column;
  float: left;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 10px;
}
.csFw{
  font-size: 10px;
  color: #A5B7CC;
  margin-left: 15px;
}
.csUnit{
  position: absolute;
  right: 15%;
  top: 55%;
  color: #A5B7CC;
}
.upload_box >>> .upload_btn {
  width: 105px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  background: #196DF7;
  text-align: center;
  color: #FFFFFF;
  line-height: 32px;
  margin-top: 10px;
}
.upload_box >>> .upload_t {
  margin: 15px 10px;
}
.ud_one {
  width: 100px;
  height: 40px;
  background: #F6F9FF;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
  margin-right: 10px;
  font-weight: bold;
}
.ud_two {
  width: 600px;
  height: 40px;
  background: #F6F9FF;
  opacity: 1;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
  margin-right: 10px;
}
.ud_three {
  width: 100px;
  height: 40px;
  opacity: 1;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
  color: #FFFFFF;
}
.ud_three1 {
  background: #1FDB8A;
  margin-right: 5px;
}
.ud_three2 {
  background: #E2335C;
}
.ud_three1 >>>.el-switch__core {
  background: #18AF6E;
  border: 1px solid #18AF6E;
}
.ud_three2 >>>.el-switch__core {
  background: #C22349;
  border: 1px solid #C22349;
}
.ud_three1 >>> .el-switch__core::after {
  background: #FFFFFF;
}
.ud_three2 >>> .el-switch__core::after {
  background: #FFFFFF;
}
.udiTitle{
  margin: 12px 0px;
  padding-left: 5px;
}
.udi_y {
  width: 10px;
  height: 10px;
  transform: rotateZ(45deg)skew(0deg,0deg);
  background: #196DF7;
}
.udi_r {
  height: 40px;
  background: #F6F9FF;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 9px 12px;
  box-sizing: border-box;
  margin-right: 10px;
  font-weight: bold;
}
.ctoradd {
  width: 100px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  font-weight: 500;
  padding: 0px 0px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #196DF7;
  box-sizing: border-box;
  cursor: pointer;
  background: #196DF7;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}
.ud_tit {
  font-size: 18px;
  font-family: 'PingFang SC';
  font-weight: 500;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
  margin-bottom: 5px;
}
.udi_i {
  display: inline-block;
  margin: 5px 7px;;
  font-size: 20px;
  color:#FF4D4F;
  cursor: pointer;
  /* line-height: 35px; */
}
.udi_a {
  width: 240px;
  height: 35px;
  background: #F6F9FF;
  border: 1px solid #196DF7;
  opacity: 1;
  border-radius: 4px;
  line-height: 35px;
  text-align: center;
  padding: 0px 0px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 3px 0;
}
.ctorBox {
  height: 600px;
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.ctorBox::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
</style>
